import React from "react";
import ProjectList from "../components/ProjectList";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "../hooks/useAuth";

const Projects: React.FC = () => {
  const { user } = useAuth();
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold mb-4">Projects</h1>
        {user && (
          <Link
            to="/projects/new"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          New Project
          </Link>
        )}
      </div>
      <ProjectList />
    </div>
  );
};

export default Projects;
