import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className="bg-white text-gray-800 py-4">
      <div className="container mx-auto flex justify-center space-x-6">
        <Link
          to="/privacy-policy"
          className="text-blue-500 hover:text-blue-700"
        >
          Privacy Policy
        </Link>
        <Link
          to="/terms-of-service"
          className="text-blue-500 hover:text-blue-700"
        >
          Terms of Service
        </Link>
      </div>
      <div className="text-center mt-2">
        &copy; {new Date().getFullYear()} Archifields. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
