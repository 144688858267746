import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import apiClient from "../api/client";
import { useAuth } from "../hooks/useAuth";

interface UserData {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  company: string;
}

const UserProfile: React.FC = () => {
  const { user, setUser } = useAuth();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiClient.get("/users/detail/");
        setUserData(response.data);
      } catch (error) {
        console.error("ユーザーデータの取得に失敗しました:", error);
      }
    };
    if (user) {
      fetchUserData();
    }
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData!,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userData) {
      console.error("User data is not available");
      return;
    }
    try {
      const dataToUpdate = {
        username: userData.username,
        first_name: userData.first_name || "",
        last_name: userData.last_name || "",
        role: userData.role || "",
        company: userData.company || "",
      };
      console.log("送信データ:", dataToUpdate);
      const response = await apiClient.patch("/users/update/", dataToUpdate);
      console.log("レスポンス:", response);
      
      setUser(response.data); // ユーザー状態を更新
      console.log("Updated user:", response.data); // デバッグ用

      setIsEditing(false);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error("エラーレスポンス:", error.response.data); // デバッグ用
      }
      console.error("ユーザーデータの更新に失敗しました:", error);
    }
  };

  const handleLogout = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      await apiClient.post("/users/logout/", { refresh_token: refreshToken });
      // ローカルストレージやセッションストレージからユーザー情報やトークンを削除
      localStorage.removeItem("token"); // JWTトークンを使用している場合
      localStorage.removeItem("refreshToken");
      setUser(null);
      // ホームページにリダイレクト
      navigate("/");
    } catch (error) {
      console.error("ログアウトに失敗しました:", error);
    }
  };

  if (!userData) return <div className="text-center mt-8">Loading...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-4">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold mb-4">User Profile</h1>
            <button
              onClick={handleLogout}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            >
              Logout
            </button>
          </div>
          {isEditing ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <InputField
                name="username"
                value={userData.username}
                onChange={handleInputChange}
                label="Username"
              />
              <InputField
                name="first_name"
                value={userData.first_name}
                onChange={handleInputChange}
                label="First Name"
              />
              <InputField
                name="last_name"
                value={userData.last_name}
                onChange={handleInputChange}
                label="Last Name"
              />
              <InputField
                name="role"
                value={userData.role}
                onChange={handleInputChange}
                label="Role"
              />
              <InputField
                name="company"
                value={userData.company}
                onChange={handleInputChange}
                label="Company"
              />
              <div className="flex space-x-2">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <div className="space-y-2">
              <ProfileField label="Username" value={userData.username} />
              <ProfileField label="Email" value={userData.email} />
              <ProfileField label="First Name" value={userData.first_name} />
              <ProfileField label="Last Name" value={userData.last_name} />
              <ProfileField label="Role" value={userData.role} />
              <ProfileField label="Company" value={userData.company} />
              <button
                onClick={() => setIsEditing(true)}
                className="mt-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
              >
                Edit Profile
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ProfileField: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <div>
    <span className="font-semibold">{label}: </span>
    <span>{value || ""}</span>
  </div>
);

const InputField: React.FC<{
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}> = ({ name, value, onChange, label }) => (
  <div>
    <label
      htmlFor={name}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
    <input
      type="text"
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    />
  </div>
);

export default UserProfile;
