// frontend/src/components/Login.tsx
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

import "../styles/GoogleSignInButton.css";
import apiClient from "../api/client";

interface GoogleUser {
  email: string;
  name: string;
}

const Login: React.FC = () => {
  const { setUser, setToken } = useAuth();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // ログイン前にユーザーがアクセスしようとしていたパスを取得
  const from = (location.state as { from?: string })?.from || "/";

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await apiClient.post("/users/login/", {
        // username: email,
        email: email,
        password: password,
      });
      const { access, refresh, user } = response.data;
      console.log("Access Token:", access);
      console.log("Refresh Token:", refresh);
      localStorage.setItem("token", access);
      localStorage.setItem("refreshToken", refresh);
      setUser(user);
      setToken(access);

      navigate(from, { replace: true });
    } catch (error) {
      console.error("Failed to login:", error);
      setError(t("invalid_email_or_password"));
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse: any) => {
    try {
      const { credential } = credentialResponse;
      const decoded: GoogleUser = jwtDecode(credential);
      // Googleから取得した情報をバックエンドに送信
      const response = await apiClient.post("/users/google-login/", {
        credential: credential,
      });
      const { access, refresh, user } = response.data;
      console.log("Access Token:", access);
      console.log("Refresh Token:", refresh);
      localStorage.setItem("token", access);
      localStorage.setItem("refreshToken", refresh);
      setUser(user);
      setToken(access);
      navigate(from, { replace: true });
    } catch (error) {
      console.error("Google login failed:", error);
      setError("Failed to login with Google.");
    }
  };

  const handleGoogleLoginFailure = () => {
    console.error("Google login failed:", error);
    setError("Failed to login with Google.");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t("login")}
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleLogin}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                {t("email")}
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                {t("password")}
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          {error && (
            <p className="mt-2 text-center text-sm text-red-600">{error}</p>
          )}

          <div className="text-center text-sm text-gray-600">
            {t("by_signing_in")}
            <a
              href="/terms-of-service"
              className="text-indigo-600 hover:text-indigo-500"
            >
              {t("terms_of_service")}
            </a>{" "}
            and{" "}
            <a
              href="/privacy-policy"
              className="text-indigo-600 hover:text-indigo-500"
            >
              {t("privacy_policy")}
            </a>
            .
          </div>
          <div className="text-center text-sm text-red-600">
            {t("If you have registered, please check your email and confirm your account before logging in.")}
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {t("login")}
            </button>
          </div>
        </form>
        <div className="text-center">
          <a
            href="/register"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {t("dont_have_an_account")} {t("signup")}
          </a>
        </div>
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-gray-50 text-gray-500">
                {t("or_continue_with")}
              </span>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginFailure}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
