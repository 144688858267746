import React, { useState } from "react";
import apiClient from "../api/client";
import axios from "axios";

const SendInvitation: React.FC = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccess(null);
    setError(null);
    try {
      await apiClient.post("/users/invite/", { email });
      setSuccess("Invitation sent successfully.");
      setEmail("");
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(
          `Failed to send invitation: ${JSON.stringify(err.response.data)}`

        );
      } else {
        setError("Failed to send invitation. Please try again later.");
      }
      console.error("Invitation error:", err);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-4">Invite User</h2>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email address of the user to invite"
          className="p-2 border rounded mb-4"
          required
        />
        <button type="submit" className="bg-blue-500 text-white p-2 rounded">
          Send Invitation
        </button>
        {success && <p className="text-green-500 mt-2">{success}</p>}
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </form>
    </div>
  );
};

export default SendInvitation;
