// frontend/src/hooks/useAuth.ts
import { useContext } from "react";
import { AuthContext } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("authTokens");
    context.setUser(null);
    // ログアウト後はトップページにリダイレクト
    navigate("/");
  };
  return { ...context, logout };
};
