import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import LanguageSwitcher from "./LanguageSwitcher";

const AppNav: React.FC = () => {
  const { user, logout } = useAuth();

  return (
    <nav className="mb-4 flex justify-between items-center">
      <ul className="flex space-x-4">
        <li>
          <Link to="/" className="text-blue-500 hover:text-blue-700">
            Home
          </Link>
        </li>
        <li>
          <Link to="/projects" className="text-blue-500 hover:text-blue-700">
            Projects
          </Link>
        </li>
        {user ? (
          <>
            <li>
              <Link to="/invite" className="text-blue-500 hover:text-blue-700">
                Invite
              </Link>
            </li>
            <li>
              <Link to="/profile" className="text-green-500">
                {user.username}
              </Link>
            </li>
            <li>
              <button onClick={logout} className="text-red-500">
                Logout
              </button>
            </li>
          </>
        ) : (
          <li>
            <Link to="/login" className="text-blue-500 hover:text-blue-700">
              Login
            </Link>
          </li>
        )}
        <li>
          <Link to="/contact" className="text-blue-500 hover:text-blue-700">
            Contact
          </Link>
        </li>
      </ul>
      <LanguageSwitcher />
    </nav>
  );
};

export default AppNav;
