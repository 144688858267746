// frontend/src/components/ProjectDetail.tsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../hooks/useAuth";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { XMarkIcon } from "@heroicons/react/24/solid";
import apiClient from "../api/client";
import ProjectInvite from "./ProjectInvite";
import ProjectCreationForm from "./ProjectCreationForm";

interface User {
  id: string;
  username: string;
}

interface ProjectInvitation {
  id: string;
  invitee: User | null;
  inviter: User | null;
  status: string;
  created_at: string;
  expires_at: string;
}

interface Thread {
  id: string;
  name: string;
}

interface Project {
  id: string;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  status: string;
  project_managers: Array<User>;
  members: Array<User>;
  pending_invitations: ProjectInvitation[];
  threads: Thread[];
  created_at: string;
  updated_at: string;
}

const ProjectDetail: React.FC = () => {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [project, setProject] = useState<Project | null>(null);
  const [newThreadName, setNewThreadName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const isProjectManager = project?.project_managers.some(
    (manager) => manager.id === user?.id
  );

  useEffect(() => {
    const fetchProject = async () => {
      if (id === "new") {
        setLoading(false);
        return;
      }
      try {
        const response = await apiClient.get(`/projects/${id}/`);
        console.log("ProjectDetail:", response.data);
        const fetchedProject: Project = response.data;
        // 無効な招待をフィルタリング
        const invalidInvitations = fetchedProject.pending_invitations.filter(
          (invitation) => invitation.invitee === null
        );
        if (invalidInvitations.length > 0) {
          await Promise.all(
            invalidInvitations.map(async (invitation) => {
              await apiClient.delete(
                `/projects/${fetchedProject.id}/invitations/`,
                {
                  params: { invitation_id: invitation.id },
                }
              );
            })
          );
          // 招待を削除後、再度プロジェクトをフェッチ
          const updatedResponse = await apiClient.get(`/projects/${id}/`);
          setProject(updatedResponse.data);
        } else {
          // 無効な招待が無い場合、プロジェクトをそのまま設定
          setProject(fetchedProject);
        }
        setLoading(false);
      } catch (err) {
        console.error("ProjectDetail:", err);
        setError("ProjectDetail: Failed to get project.");
        setLoading(false);
      }
    };
    fetchProject();
    console.log("Current ID:", id);
  }, [id]);

  const handleCreateThread = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await apiClient.post("/chat/threads/", {
        project: id,
        name: newThreadName,
      });
      setProject((prevProject: Project | null) => {
        if (prevProject === null) return null;
        return {
          ...prevProject,
          threads: [...(prevProject.threads || []), response.data],
        };
      });
      setNewThreadName("");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error("Failed to create thread:", err.response?.data);
        setError(
          `Failed to create thread: ${
            err.response?.data?.detail || err.message
          }`
        );
      } else {
        console.error("An unexpected error occurred:", err);
        setError("An unexpected error occurred");
      }
    }
  };

  const handleCancelInvitation = async (invitationId: string) => {
    // 招待をキャンセル
    try {
      await apiClient.delete(
        `/projects/${project?.id}/invitations/${invitationId}/`
      );
      // プロジェクトデータを再フェッチして更新
      const response = await apiClient.get(`/projects/${id}/`);
      setProject(response.data);
    } catch (err) {
      console.error("ProjectDetail: Failed to cancel invitation:", err);
      alert("ProjectDetail: Failed to cancel invitation.");
    }
  };

  const handleLeaveProject = async () => {
    const confirmMessage =
      "Are you sure you want to leave this project?\n" +
      "Once you leave, you cannot return.\n" +
      "To rejoin the project, you must be invited again by an project manager.";
    if (window.confirm(confirmMessage)) {
      try {
        await apiClient.post(`/projects/${id}/leave/`);
        alert("ProjectDetail: Left project successfully.");
        navigate("/projects");
      } catch (err: any) {
        console.error("Failed to leave project:", err);
        const errorMessage =
          err.response?.data?.detail || "Failed to leave project";
        alert(errorMessage);
      }
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (id === "new") {
    return (
      <div className="max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-3xl font-bold mb-4">Create New Project</h1>
        <ProjectCreationForm />
      </div>
    );
  }
  if (error || !project) {
    return <div className="text-center mt-8">Project not found.</div>;
  }

  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <h1 className="text-3xl font-bold mb-4">{project.name}</h1>
      <p className="text-gray-600 mb-4">{project.description}</p>
      <div className="mt-4">
        <h2 className="text-xl font-semibold mb-2">Chat Threads</h2>
        {project.threads && project.threads.length > 0 ? (
          project.threads.map((thread: Thread) => (
            <Link
              key={thread.id}
              to={`/chat/threads/${thread.id}`}
              className="block p-2 mb-2 bg-gray-100 rounded hover:bg-gray-200"
            >
              {thread.name}
            </Link>
          ))
        ) : (
          <p>No chat threads available.</p>
        )}
        <form onSubmit={handleCreateThread} className="mb-4 flex flex-col">
          <input
            type="text"
            value={newThreadName}
            onChange={(e) => setNewThreadName(e.target.value)}
            placeholder="New thread name"
            className="border rounded p-2 mb-2 w-full"
            required
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded w-50 self-start"
          >
            Create Thread
          </button>
        </form>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="font-semibold">Start:</p>
          <p>{project.start_date}</p>
        </div>
        <div>
          <p className="font-semibold">End:</p>
          <p>{project.end_date}</p>
        </div>
      </div>
      <div className="mt-4">
        <p className="font-semibold">Project Managers:</p>
        <ul>
          {project.project_managers.map((manager: User) => (
            <li key={manager.id}>
              <Link
                to={`/profile/${manager.id}`}
                className="text-blue-500 hover:text-blue-700"
              >
                {manager.username}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4">
        <p className="font-semibold">Members:</p>
        <ul>
          {project.members.map((member: User) => (
            <li key={member.id}>
              <Link
                to={`/profile/${member.id}`}
                className="text-blue-500 hover:text-blue-700"
              >
                {member.username}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4">
        <p className="font-semibold">Pending Invitations:</p>
        <ul>
          {project.pending_invitations.map((invitation: ProjectInvitation) => (
            <li key={invitation.id} className="flex items-center">
              <Link
                to={`/profile/${invitation.invitee?.id}`}
                className="text-blue-500 hover:text-blue-700"
              >
                {invitation.invitee?.username || "No invitee"}
              </Link>
              {invitation.status === "pending" && (
                <button
                  onClick={() => handleCancelInvitation(invitation.id)}
                  className="ml-2 text-red-500 bg-transparent px-2 py-1 rounded text-xs hover:bg-red-100"
                  aria-label="Cancel Invitation"
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
              )}
            </li>
          ))}
          {project.pending_invitations.length === 0 && (
            <p>No pending invitations.</p>
          )}
        </ul>
      </div>
      <ProjectInvite
        projectId={project.id.toString()}
        onInviteSuccess={() => {
          // メンバーリストを再取得するためにプロジェクトデータを再フェッチ
          apiClient.get(`/projects/${id}/`).then((response) => {
            setProject(response.data);
          });
        }}
      />
      <hr className="my-4" />
      {isProjectManager && (
        <Link
          to={`/projects/${id}/edit`}
          className="bg-yellow-500 text-white mt-4 px-4 py-2 rounded mb-4"
        >
          Edit Project
        </Link>
      )}
      <hr className="my-4" />
      <button
        onClick={handleLeaveProject}
        className="bg-red-500 text-white mt-4 px-4 py-2 rounded mb-4 block"
      >
        Leave Project
      </button>
    </div>
  );
};

export default ProjectDetail;
