import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import apiClient from "../api/client";
import { Project } from "../types/project";

const ProjectEditForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await apiClient.get(`/projects/${id}/`);
        const project: Project = response.data;
        setName(project.name);
        setDescription(project.description);
        setStartDate(project.start_date);
        setEndDate(project.end_date);
        setStatus(project.status);
      } catch (error) {
        console.error("Project data fetch failed:", error);
        navigate("/projects");
      }
    };
    if (id) {
      fetchProject();
    }
  }, [id, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const payload = {
        name,
        description: description || undefined,
        start_date: startDate || undefined,
        end_date: endDate || undefined,
        status: status || undefined,
      };
      const response = await apiClient.patch(`/projects/${id}/`, payload);
      console.log("Project edit success:", response.data);
      navigate(`/projects/${id}`);
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        console.error("Error response:", error.response.data);
        setErrors(error.response.data);
      } else {
        console.error("Project edit failed:", error);
      }
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700">Project Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
          {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
        </div>
        <div>
          <label className="block text-gray-700">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border rounded"
          />
          {errors.description && (
            <p className="text-red-500 text-xs">{errors.description}</p>
          )}
        </div>
        <div>
          <label className="block text-gray-700">Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full p-2 border rounded"
          />
          {errors.start_date && (
            <p className="text-red-500 text-xs">{errors.start_date}</p>
          )}
        </div>
        <div>
          <label className="block text-gray-700">End Date</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full p-2 border rounded"
          />
          {errors.end_date && (
            <p className="text-red-500 text-xs">{errors.end_date}</p>
          )}
        </div>
        <div>
          <label className="block text-gray-700">Status</label>
          <input
            type="text"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full p-2 border rounded"
          />
          {errors.status && (
            <p className="text-red-500 text-xs">{errors.status}</p>
          )}
        </div>
        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            Save
          </button>
          <button
            type="button"
            onClick={() => navigate(`/projects/${id}`)}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProjectEditForm;
