import React from "react";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-4xl font-bold mb-4">404 - Page not found</h1>
      <p className="mb-4">
        The page you are looking for does not exist or may have been deleted.
      </p>
      <Link to="/" className="text-blue-500 hover:text-blue-700">
        Go to Home
      </Link>
    </div>
  );
};

export default NotFound;
