import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// 翻訳ファイルのインポート
import translationEN from "./locales/en/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationES from "./locales/es/translation.json";

// 使用する言語リソースの定義
const resources = {
  en: {
    translation: translationEN,
  },
  ja: {
    translation: translationJA,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(LanguageDetector) // ユーザーのブラウザ設定を検出
  .use(initReactI18next) // react-i18nextを初期化
  .init({
    resources,
    fallbackLng: "en", // デフォルトの言語
    interpolation: {
      escapeValue: false, // ReactはXSS対策済み
    },
  });

export default i18n;
