import { useState, useEffect } from "react";
import axios from "../api/client";
import { CustomError } from "../types/CustomError";

interface Project {
  id: number;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  status: string;
  // 他のフィールド...
}

interface UseProjectsResult {
  projects: Project[];
  loading: boolean;
  error: CustomError | null;
}

export const useProjects = (token: string | null): UseProjectsResult => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<CustomError | null>(null);

  useEffect(() => {
    // トークンが存在しない場合、API呼び出しを行わない
    if (!token) {
      setLoading(false);
      setProjects([]);
      setError(null);
      return;
    }

    const fetchProjects = async () => {
      try {
        const response = await axios.get("/projects/");
        setProjects(response.data);
      } catch (err: any) {
        const customError: CustomError = err.response
          ? { ...err.response.data, status: err.response.status }
          : { message: err.message, status: 500 };
        setError(customError);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [token]);

  return { projects, loading, error };
};
