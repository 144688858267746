import React, { useState, useRef } from "react";
import apiClient from "../api/client";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";

const ContactForm: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [inquiryType, setInquiryType] = useState("general");
  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState<File[]>([]);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAttachments(Array.from(e.target.files));
    }
  };

  const handleRemoveFile = (index: number) => {
    setAttachments((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      setError("Missing reCAPTCHA token. Please try again.");
      return;
    }

    const token = await executeRecaptcha("contact_form");
    setCaptchaToken(token);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("inquiry_type", inquiryType);
    formData.append("message", message);
    formData.append("captcha_token", token);

    attachments.forEach((file) => {
      formData.append("attachments", file);
    });

    try {
      await apiClient.post("/inquiries/contact/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSuccess("Your inquiry has been sent successfully.");
      setError(null);
      setName("");
      setEmail("");
      setInquiryType("general");
      setMessage("");
      setAttachments([]);
      setCaptchaToken(null);
      // ファイル入力をリセット
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      // CAPTCHAのリセットはライブラリに依存
    } catch (err: any) {
      if (err.response && err.response.data) {
        setError(Object.values(err.response.data).join(", "));
      } else {
        setError("Failed to send your inquiry. Please try again later.");
      }
      setSuccess(null);
    }
  };

  console.log("ReCAPTCHA site key:", process.env.REACT_APP_RECAPTCHA_SITE_KEY);

  return (
    <div className="max-w-lg mx-auto mt-10 p-6 bg-white shadow-md rounded">
      <Helmet>
        <title>Contact Us - Archifields</title>
        <meta
          name="description"
          content="Get in touch with the Archifields team for any inquiries or support."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
      {success && <p className="text-green-500 mb-4">{success}</p>}
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="name">
            Name<span className="text-red-500">*</span>
          </label>
          <input
            id="name"
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            placeholder="Your Name"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="email">
            Email<span className="text-red-500">*</span>
          </label>
          <input
            id="email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            placeholder="Your Email"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="inquiryType">
            Inquiry Type<span className="text-red-500">*</span>
          </label>
          <select
            id="inquiryType"
            value={inquiryType}
            onChange={(e) => setInquiryType(e.target.value)}
            className="w-full px-3 py-2 border rounded"
          >
            <option value="general">General Inquiry</option>
            <option value="improvement">Improvement Request</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="message">
            Message<span className="text-red-500">*</span>
          </label>
          <textarea
            id="message"
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            placeholder="Your Message"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="attachments">
            Attach Files
          </label>
          <input
            id="attachments"
            type="file"
            multiple
            onChange={handleFileChange}
            className="w-full"
          />
          {attachments.length > 0 && (
            <div className="mt-2">
              <p>Attached Files:</p>
              <ul>
                {attachments.map((file, index) => (
                  <li key={index} className="flex items-center">
                    <span>{decodeURIComponent(file.name)}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveFile(index)}
                      className="ml-2 text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {/* <div className="mb-4"> */}
        {/* <ReCAPTCHA */}
        {/* sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""} */}
        {/* onChange={(token: string | null) => setCaptchaToken(token)} */}
        {/* /> */}
        {/* </div> */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

const WrappedContactForm: React.FC = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}>
    <ContactForm />
  </GoogleReCaptchaProvider>
);

export default WrappedContactForm;
