import React from "react";
import { useTranslation } from "react-i18next";
import { languages, Language } from "../languages";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div>
      <select
        value={i18n.language}
        onChange={handleChange}
        className="border border-gray-300 rounded px-2 py-1"
      >
        {languages.map((lang: Language) => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSwitcher;
