import React from "react";
import ReactMarkdown from "react-markdown";

const termsOfServiceMarkdown = `
### Terms of Service

**Effective Date:** September 23, 2024

**1. Acceptance of Terms**

By accessing and using the Archifields website, you accept and agree to be bound by the terms and provision of this agreement. If you do not agree to abide by the above, please do not use this service.

**2. Description of Service**

Archifields provides project management and collaboration tools to help teams organize and manage their work efficiently.

**3. User Responsibilities**

Users agree to use the service only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the service.

**4. Account Security**

You are responsible for safeguarding the password that you use to access the service and for any activities or actions under your password.

**5. Termination**

We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, for any reason whatsoever.

**6. Limitation of Liability**

In no event shall Archifields, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages.

**7. Changes to Terms**

We reserve the right, at our sole discretion, to modify or replace these Terms at any time. It is your responsibility to check these Terms periodically for changes.

**8. Governing Law**

These Terms shall be governed and construed in accordance with the laws of Japan, without regard to its conflict of law provisions.

**9. Contact Us**

If you have any questions about these Terms, please [contact us](/contact).
`;

const TermsOfService: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <div className="prose">
        <ReactMarkdown>{termsOfServiceMarkdown}</ReactMarkdown>
      </div>
    </div>
  );
};

export default TermsOfService;
