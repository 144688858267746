/**
 * frontend/src/components/ProjectInvite.tsx
 */
import React, { useState } from "react";
import apiClient from "../api/client";
import axios from "axios";

interface ProjectInviteProps {
  projectId: string;
  onInviteSuccess: () => void;
}

const ProjectInvite: React.FC<ProjectInviteProps> = ({
  projectId,
  onInviteSuccess,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      const response = await apiClient.post(
        `/projects/${projectId}/invite_member/`,
        {
          invitee_email: email,
        }
      );
      console.log("ProjectInvite:", response.data);
      setEmail("");
      onInviteSuccess();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        let errorMsg =
          err.response.data.error || "Failed to send invitation.";
        if (errorMsg === "This user is not active.") {
          errorMsg += "Please check if the user is active.";
        }
        if (errorMsg === "User with this email does not exist.") {
          errorMsg += "Please invite them to Archifields.";
        }
        setError(errorMsg);
      } else {
        setError("Failed to send invitation.");
      }
      console.error("ProjectInvite:", err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <p className="mb-2">
        Please enter the email address of the user you want to invite.
      </p>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="User's email address"
        className="w-full p-2 border rounded"
        required
      />
      {error && <p className="text-red-500 mt-2">{error}</p>}
      <button
        type="submit"
        className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
      >
        Invite
      </button>
    </form>
  );
};

export default ProjectInvite;
