// frontend/src/api/client.ts
import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:8000/";
const API_BASE_URL = `${BASE_URL}api/`;

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

// 初回ロード時にCSRFトークンを取得
if (!Cookies.get("csrftoken")) {
  // axios.get("/csrf/")
  axios.get(`${BASE_URL}csrf/`)
    .then(response => {
      const newCsrftoken = response.data.csrfToken;
      Cookies.set("csrftoken", newCsrftoken);
    })
    .catch(error => {
      console.error("CSRFトークンの取得に失敗しました:", error);
    });
}

// リクエストインターセプターでトークンを追加
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      console.log("Authorization Token:", token);
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    // トークンがない場合は何もしない
    return config;
  },
  (error) => Promise.reject(error)
);

// レスポンスインターセプターでエラーハンドリング
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log("認証エラー: トークンを削除またはリフレッシュしますか？");
    }
    return Promise.reject(error);
  }
);

export default apiClient;
