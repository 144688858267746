import React, { useEffect, useState } from "react";
// import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import apiClient from "../api/client";

const InvitationAccept: React.FC = () => {
  const { projectId, invitationId } = useParams<{ projectId: string; invitationId: string }>();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [invitationValid, setInvitationValid] = useState<boolean>(false);
  const [projectInfo, setProjectInfo] = useState<any>(null);

  useEffect(() => {
    const checkInvitation = async () => {
      try {
        await apiClient.get(
          `/projects/${projectId}/invitations/${invitationId}/`
        );
        // プロジェクト情報の取得
        const projectResponse = await apiClient.get(
          `/projects/${projectId}/`
        );
        setProjectInfo(projectResponse.data);
        setInvitationValid(true);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching invitation:", error);
        setMessage("Invitation not found or already responded.");
        setLoading(false);
      }
    };

    // ユーザーがログインしているか確認
    const user = localStorage.getItem('token');
    if (user) {
      console.log("User is logged in.");
      checkInvitation();
    } else {
      console.log("User is not logged in.");
      setMessage("Please log in to accept the invitation.");
      navigate("/login", { state: { from: `/projects/${projectId}/invitations/${invitationId}/accept` } });
    }
  }, [invitationId, projectId, navigate]);

  const handleAccept = async () => {
    try {
      const response = await apiClient.post(
        `/projects/${projectId}/invitations/${invitationId}/accept/`
      );
      setMessage("Invitation accepted. Redirecting to projects.");
      setTimeout(() => {
        navigate("/projects");
      }, 3000);
    } catch (error) {
      console.error("Error accepting invitation:", error);
      setMessage("An error occurred while accepting the invitation.");
    }
  };

  const handleReject = async () => {
    try {
      const response = await apiClient.post(
        `/projects/${projectId}/invitations/${invitationId}/reject/`
      );
      setMessage("Invitation rejected.");
    } catch (error) {
      console.error("Error rejecting invitation:", error);
      setMessage("An error occurred while rejecting the invitation.");
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-bold">Loading...</h1>
      </div>
    );
  }

  if (message) {
    return (
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-bold">{message}</h1>
      </div>
    );
  }

  if (invitationValid && projectInfo) {
    return (
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-bold mb-4">
          You have been invited to join the project "{projectInfo.name}".
        </h1>
        <div className="mb-6">
          <p><strong>Description:</strong> {projectInfo.description}</p>
          <p><strong>Start Date:</strong> {projectInfo.start_date}</p>
          <p><strong>End Date:</strong> {projectInfo.end_date}</p>
          <p><strong>Project Manager:</strong> {projectInfo.project_managers.map((manager: any) => manager.username).join(", ")}</p>
          <p><strong>Members:</strong> {projectInfo.members.map((member: any) => member.username).join(", ")}</p>
        </div>
        <button
          onClick={handleAccept}
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded m-2"
        >
          Accept
        </button>
        <button
          onClick={handleReject}
          className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded m-2"
        >
          Reject
        </button>
      </div>
    );
  } else {
    return (
      <div className="container mx-auto px-4">
        <h1 className="text-2xl font-bold">Invalid invitation.</h1>
      </div>
    );
  }
};

export default InvitationAccept;
