// frontend/src/components/ProjectCreationForm.tsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiClient from "../api/client";

const ProjectCreationForm: React.FC = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const payload = {
        name,
        description: description || undefined,
        start_date: startDate || undefined,
        end_date: endDate || undefined,
        status: status || undefined,
      };
      const response = await apiClient.post("/projects/", payload);
      console.log("プロジェクト作成成功:", response.data);
      navigate(`/projects/${response.data.id}`);
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        console.error("エラーレスポンス:", error.response.data);
        setErrors(error.response.data);
      } else {
        console.error("プロジェクト作成に失敗しました:", error);
      }
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block mb-1">
            Project Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={`w-full px-3 py-2 border rounded ${
              errors.name ? "border-red-500" : ""
            }`}
            required
          />
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name}</p>
          )}
        </div>
        <div>
          <label htmlFor="description" className="block mb-1">
            Description (Optional)
          </label>
          <textarea

            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={`w-full px-3 py-2 border rounded ${
              errors.description ? "border-red-500" : ""
            }`}
          />
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description}</p>
          )}
        </div>
        <div>
          <label htmlFor="startDate" className="block mb-1">
            Start Date (Optional)
          </label>
          <input

            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className={`w-full px-3 py-2 border rounded ${
              errors.start_date ? "border-red-500" : ""
            }`}
          />
          {errors.start_date && (
            <p className="text-red-500 text-sm mt-1">{errors.start_date}</p>
          )}
        </div>
        <div>
          <label htmlFor="endDate" className="block mb-1">
            End Date (Optional)
          </label>

          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className={`w-full px-3 py-2 border rounded ${
              errors.end_date ? "border-red-500" : ""
            }`}
          />
          {errors.end_date && (
            <p className="text-red-500 text-sm mt-1">{errors.end_date}</p>
          )}
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
        >
          Create Project
        </button>
      </form>

    </div>
  );
};

export default ProjectCreationForm;
