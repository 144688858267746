import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Home: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  useEffect(() => {
    // LocalStorageからtokenを取得してLogin状態を確認
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(!!token);
    }
  }, []);
  useEffect(() => {
    // Login状態が変わった時に対応するためのListener
    const handleStorageChange = () => {
      const token = localStorage.getItem("token");
      setIsLoggedIn(!!token);
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [isLoggedIn]);
  return (
    <HelmetProvider>
      <div className="container mx-auto px-4 py-8">
        <Helmet>
          <title>Archifields - Construction Project Management Tool</title>
          <meta charSet="UTF-8" />
          <meta
            name="description"
            content="Archifields is a tool that supports smooth communication and collaboration among stakeholders in construction projects. It addresses all your needs with project management, chat features, user profiles, and more."
          />
          <link rel="canonical" href="https://archifields.io" />
          <meta name="robots" content="index, follow" />
        </Helmet>
        {/* Hero Section */}
        <section className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Welcome to Archifields</h1>
          <p className="text-lg text-gray-700 mb-4">
            Archifields is a tool that supports smooth communication and
            collaboration among stakeholders in construction projects. It caters
            to all your needs with project management, chat features, user
            profiles, and more.
          </p>
          <p className="text-md text-green-600 mb-6">
            Currently in beta version and available for free.
          </p>
          <div className="mt-6">
            {!isLoggedIn ? (
              <>
                <Link
                  to="/register"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
                >
                  Register Now
                </Link>
                <Link
                  to="/login"
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Login
                </Link>
              </>
            ) : (
              <Link
                to="/projects"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Go to Projects
              </Link>
            )}
          </div>
        </section>

        {/* Features */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-6">Key Features</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-6 w-6 text-blue-500 mr-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-bold">Project Management</h3>
                <p className="text-gray-600">
                  Easily monitor project progress, assign tasks, and manage
                  schedules efficiently.
                </p>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-6 w-6 text-green-500 mr-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8h2a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V10a2 2 0 012-2h2"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-bold">Real-Time Chat</h3>
                <p className="text-gray-600">
                  Communicate with team members in real-time to support swift
                  decision-making.
                </p>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-6 w-6 text-purple-500 mr-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-bold">User Profiles</h3>
                <p className="text-gray-600">
                  Manage each member's profile and easily view roles and contact
                  information.
                </p>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-6 w-6 text-yellow-500 mr-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 7h18M3 12h18M3 17h18"
                  />
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-bold">
                  Invitation and Participation Management
                </h3>
                <p className="text-gray-600">
                  Easily invite new members and manage their participation in
                  projects.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-6 text-center">
            Testimonials
          </h2>
          <div className="space-y-6">
            <div className="bg-gray-100 p-6 rounded-lg">
              <p className="text-gray-700">
                "Since implementing Archifields, communication between teams has
                significantly improved. Project management has become easier,
                and we are progressing on schedule."
              </p>
              <p className="mt-4 text-right font-semibold"></p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg">
              <p className="text-gray-700">
                "The real-time chat feature is extremely useful, making
                information sharing between the site and office smooth."
              </p>
              <p className="mt-4 text-right font-semibold"></p>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <section className="text-center">
          <h2 className="text-2xl font-semibold mb-4">Get Started Now</h2>
          <p className="text-gray-700 mb-6">
            Streamline your project management with Archifields and maximize
            your team's productivity. Try our free trial today.
          </p>
          <Link
            to="/register"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded"
          >
            Start Free Trial
          </Link>
        </section>
      </div>
    </HelmetProvider>
  );
};

export default Home;
