import React from "react";
import ReactMarkdown from "react-markdown";

const privacyPolicyMarkdown = `
### Privacy Policy

**Effective Date:** September 23, 2024

**1. Introduction**

Welcome to Archifields. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices regarding your personal information, please contact us at archifields.mulberryfields@gmail.com.

**2. Information We Collect**

We collect personal information that you voluntarily provide to us when registering on the website, expressing an interest in obtaining information about us or our products and services, when participating in activities on the website or otherwise contacting us.

**3. How We Use Your Information**

We use personal information collected via our website for a variety of business purposes, including:
- To facilitate account creation and logon process.
- To send administrative information to you.
- To fulfill and manage your orders.
- To send you marketing and promotional communications.
- To manage user accounts.

**4. Sharing Your Information**

We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.

**5. Security of Your Information**

We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable.

**6. Your Privacy Rights**

Depending on your location, you may have the right to:
- Access your personal information.
- Correct any personal information we hold about you.
- Request deletion of your personal information.
- Object to or restrict certain types of processing of your personal information.

**7. Changes to This Privacy Policy**

We may update this Privacy Policy from time to time. The updated version will be indicated by an updated “Effective Date” and will be effective as soon as it is accessible.

**8. Contact Us**

If you have questions or comments about this policy, please [contact us](/contact).
`;

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <div className="prose">
        <ReactMarkdown>{privacyPolicyMarkdown}</ReactMarkdown>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
