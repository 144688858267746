// frontend/src/components/ThreadDetail.tsx
import axios from "axios";
import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import Modal from "react-modal";
import apiClient from "../api/client";
import { Thread, Message } from "../types/ChatMessage";
import { User } from "../types/User";
import { useAuth } from "../hooks/useAuth";
import { Project } from "../types/project";
import { Helmet } from "react-helmet";

// Modalのルート要素を設定
Modal.setAppElement("#root");

const ThreadDetail: React.FC = () => {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [thread, setThread] = useState<Thread | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [projectMembers, setProjectMembers] = useState<User[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [addingParticipants, setAddingParticipants] = useState(false);
  const [project, setProject] = useState<Project | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<string>("");

  // メッセージの最後の位置を保持するためのref
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchThread = async () => {
      try {
        const response = await apiClient.get(`/chat/threads/${id}/`);
        setThread(response.data);
        setMessages(response.data.messages);
        setLoading(false);
        scrollToBottom();
      } catch (err) {
        console.error("スレッドの取得に失敗しました:", err);
        setError("Failed to load thread.");
        setLoading(false);
      }
    };

    fetchThread();
  }, [id]);

  useEffect(() => {
    // メッセージが更新されたときに、最後の位置にスクロール
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const availableMembers = projectMembers.filter(
    (member) =>
      !thread?.participants.some((participant) => participant.id === member.id)
  );

  const handleCreateThread = async (e: React.FormEvent) => {
    // 既存のスレッド作成ロジック
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newMessage.trim() === "" && files.length === 0) {
      alert("Please enter a message or attach a file.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("content", newMessage);
      files.forEach((file) => formData.append("files", file));
      // フォームデータの中身を確認
      formData.forEach((value, key) => {
        console.log(`フォームデータの内容:${key}: ${value}`);
      });
      const response = await apiClient.post(
        `chat/threads/${id}/messages/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessages((prevMessages) => [...prevMessages, response.data]);
      setNewMessage("");
      setFiles([]); // アップロードしたファイルをリセット
      console.log("ファイルをリセットしました:", files);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        console.error("サーバーからのエラーレスポンス:", err.response.data);
      } else {
        console.error("メッセージ送信エラー:", err);
      }
      alert("Failed to send message.");
    }
  };

  // 関数名をDecode
  const renderAttachment = (attachment: {
    file_path: string;
    file_type: string;
  }) => {
    const fileName = decodeURIComponent(
      attachment.file_path.split("/").pop() || "file"
    );
    if (attachment.file_type.startsWith("image/")) {
      return (
        <>
          <img
            src={attachment.file_path}
            alt="Attachment"
            className="max-w-xs max-h-32 mt-2 cursor-pointer"
            onClick={() => {
              setCurrentImage(attachment.file_path);
              setModalIsOpen(true);
            }}
          />
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Image Modal"
            className="customModal"
            overlayClassName="customOverlay"
          >
            <img
              src={currentImage}
              alt="Modal Image"
              className="modalImage"
            />
            <button
              onClick={() => setModalIsOpen(false)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Close
            </button>
          </Modal>
        </>
      );
    } else {
      return (
        <a
          href={attachment.file_path}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          {fileName}
        </a>
      );
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles([...files, ...Array.from(e.target.files)]);
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleAddParticipants = async () => {
    if (selectedMembers.length === 0) {
      alert("Select members to add.");
      return;
    }
    setAddingParticipants(true);
    try {
      const response = await apiClient.post(
        `/chat/threads/${id}/add_participants/`,
        {
          user_ids: selectedMembers,
        }
      );
      console.log("参加者追加成功:", response.data);
      // スレッドのデータを再取得して更新
      const updatedThread = await apiClient.get(`chat/threads/${id}/`);
      setThread(updatedThread.data);
      setSelectedMembers([]);
      // alert("Participants added successfully.");
    } catch (err) {
      console.error("参加者追加エラー:", err);
      alert("Failed to add participants.");
    } finally {
      setAddingParticipants(false);
    }
  };

  const handleMemberSelection = (userId: string) => {
    setSelectedMembers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <h1 className="text-2xl font-bold mb-4">
        {thread?.name || "Loading..."}
      </h1>

      {/* スレッドのメッセージ一覧 */}
      <div className="mb-4 h-auto">
        {messages && messages.length > 0 ? (
          messages.map(
            (message) =>
              message &&
              message.sender && (
                <div
                  key={message.id}
                  className={`mb-4 ${
                    message.sender.id === user?.id ? "text-right" : "text-left"
                  }`}
                >
                  <div
                    className={`inline-block p-2 rounded-lg ${
                      message.sender.id === user?.id
                        ? "bg-blue-100"
                        : "bg-gray-100"
                    }`}
                  >
                    <p>
                      <Link
                        to={`/profile/${message.sender.id}`}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        {message.sender.username}
                      </Link>
                    </p>
                    <p>{message.content}</p>
                    {message.attachments &&
                      message.attachments.length > 0 &&
                      message.attachments.map(
                        (attachment) =>
                          attachment && (
                            <div key={attachment.id} className="mt-2">
                              {renderAttachment(attachment)}
                            </div>
                          )
                      )}
                    <small className="text-gray-500">
                      {new Date(message.sent_at).toLocaleString()}
                    </small>
                  </div>
                </div>
              )
          )
        ) : (
          <p>メッセージがありません。</p>
        )}
        <div ref={messagesEndRef} />
      </div>

      {/* メッセージ送信フォーム */}
      <form onSubmit={handleSendMessage}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="w-full p-2 border rounded"
          placeholder="Type a message..."
        />
        <input
          type="file"
          onChange={handleFileChange}
          className="mt-2"
          multiple
        />
        {files.length > 0 && (
          <div className="mt-2">
            <p>Attached Files:</p>
            <ul>
              {files.map((file, index) => (
                <li key={index} className="flex items-center">
                  <span>{decodeURIComponent(file.name)}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(index)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <button
          type="submit"
          className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Send
        </button>
      </form>

      <div className="mb-4">
        <h2 className="text-1xl font-bold mb-4">{project?.name}</h2>
        <p className="font-semibold">Participants</p>
        <ul>
          {thread?.participants && thread.participants.length > 0 ? (
            thread.participants.map((member: User) =>
              member?.id ? (
                <li key={member.id}>
                  <Link
                    to={`/profile/${member.id}`}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {member.username}
                  </Link>
                </li>
              ) : null
            )
          ) : (
            <li>No participants</li>
          )}
        </ul>
      </div>

      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-2">Add Participants</h2>
        {availableMembers.length > 0 ? (
          <div className="mb-2">
            {availableMembers.map((member) => (
              <label key={member.id} className="block">
                <input
                  type="checkbox"
                  value={member.id}
                  checked={selectedMembers.includes(member.id)}
                  onChange={() => handleMemberSelection(member.id)}
                  className="mr-2"
                />
                {member.username}
              </label>
            ))}
          </div>
        ) : (
          <p>No available candidates.</p>
        )}
        <button
          onClick={handleAddParticipants}
          disabled={addingParticipants}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          {addingParticipants ? "Adding..." : "Add Participants"}
        </button>
      </div>
    </div>
  );
};

export default ThreadDetail;
