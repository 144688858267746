import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../api/client";

const EmailConfirmation: React.FC = () => {
  const [message, setMessage] = useState("");
  const { uid, token } = useParams<{ uid: string; token: string }>();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await apiClient.get(
          `/users/confirm-email/${uid}/${token}/`
        );
        setMessage("Email confirmed successfully. You can now log in.");
      } catch (error) {
        setMessage(
          "Email confirmation failed. Please try again or contact support."
        );
      }
    };

    confirmEmail();
  }, [uid, token]);

  return <div>{message}</div>;
};

export default EmailConfirmation;
