// frontend/src/App.tsx
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import ProjectDetail from "./components/ProjectDetail";
import ProjectEditForm from "./components/ProjectEditForm";
import ThreadDetail from "./components/ThreadDetail";
import Login from "./components/Login";
import Register from "./components/Register";
import EmailConfirmation from "./components/EmailConfirmation";
import UserProfile from "./pages/UserProfile";
import { AuthProvider } from "./components/AuthContext";
// import { useAuth } from "./hooks/useAuth";
import InvitationAccept from "./components/InvitationAccept";
import SendInvitation from "./components/SendInvitation";
import NotFound from "./components/NotFound";
import UserProfileView from "./components/UserProfileView";
import ContactForm from "./components/ContactForm";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import Footer from "./components/Footer";
import AppNav from "./components/AppNav";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/:id" element={<ProjectDetail />} />
      <Route path="/projects/:id/edit" element={<ProjectEditForm />} />
      <Route path="/chat/threads/:id" element={<ThreadDetail />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/invite" element={<SendInvitation />} />
      <Route path="/confirm-email/:uid/:token" Component={EmailConfirmation} />
      <Route path="/profile" element={<UserProfile />} />
      <Route path="/profile/:id" element={<UserProfileView />} />
      <Route path="/:projectId/invitations/:invitationId/" element={<InvitationAccept />} />
      <Route path="/contact" element={<ContactForm />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
        <Router>
          <div className="flex flex-col min-h-screen">
            <div className="container mx-auto p-4">
              <AppNav />
              <AppRoutes />
            </div>
            <Footer />
          </div>
        </Router>
      </GoogleOAuthProvider>
    </AuthProvider>
  );
}

export default App;
